<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      v-for="l in ligen"
      :key="l._id"
      :title="l.name"
      color="grey darken-2"
    >
      <v-row>
        <v-col lg="3" md="6" cols="12">
          <base-material-card
            icon="far fa-ranking-star"
            title="Tabellen"
            color="primary"
          >
            <p>Tabelle {{ l.name }}</p>
            <v-btn block @click="tabellen(l._id)">Download</v-btn>
          </base-material-card>
        </v-col>
        <v-col lg="3" md="6" cols="12">
          <base-material-card
            icon="far fa-file-certificate"
            title="Urkunden"
            color="primary"
          >
            <p>Urkunden {{ l.name }}</p>
            <v-btn block @click="urkunden(l._id)">Download</v-btn>
          </base-material-card>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'print',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    ligen () {
      return this.EventFind.slice().sort((a, b) => a.order < b.order ? -1 : 1)
    }
  },

  apollo: {
    EventFind: {
      query: gql`query($parent: UUID!) {
        EventFind(parent: $parent) {
          _id
          ... on StbW2023League {
            name order
            parent { _id }
          }
        }
      }`,
      variables () {
        return {
          parent: this.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
